import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide51/dataPageGuide51"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
      5 mejores formas de usar un software omnicanal 
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/software-omnicanal/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre las 5 mejores formas de aprovechar al máximo un software omnicanal para mejorar la experiencia del cliente y aumentar la eficiencia operativa. "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="5 mejores formas de usar un software omnicanal "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/software-omnicanal/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia51.jpg`}
      />
      <meta
        property="og:description"
        content="Descubre las 5 mejores formas de aprovechar al máximo un software omnicanal para mejorar la experiencia del cliente y aumentar la eficiencia operativa. "
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Atención al cliente"
      location={location}
      typeGuide="guide51"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
